$common-box-shadow: 0px 1px 2px #00000008;

.billing-details-body {
    height: 200px;
    box-shadow: $common-box-shadow;
    border-radius: 0px 0px 8px 8px;
}

.subscription-card-body {
    height: 154px;
    box-shadow: $common-box-shadow;
    border-radius: 8px;
    padding: 30px;
}

.divider-dark-grey {
    border-bottom: 2px solid #0000001a;
}

.need-assistance-text-color {
    color: #00692b;
}

.institute-icon-bg {
    border-radius: 50%;
    background-color: #e5f8f1;
}

.recruiter-icon-bg {
    border-radius: 50%;
    background-color: #E5F6FE;
}

.no-subscription-screen {
    height: 70vh;
}

.no-subscription-img {
    mix-blend-mode: multiply;
}

.billing-address-width-truncate {
    width: 525px;
}

// .view-packages-button {
//     border: 2px solid #000000;
//     border-radius: 22px;
// }

.package-box-body {
    border-radius: 16px;
    border-radius: 16px;
    width: 278px;
    min-height: 335px !important;
    max-height: fit-content !important;
    width: 278px;
}

.subscription-card-body-height {
    max-height: 64vh;
    overflow-y: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}

.package-card-border {
    border: 2px solid #0000001a;
}

.card-title-padding {
    padding: 22px 0px 0px 30px;
}

.plan-Btn-padding {
    bottom: 28px;
}

.black-chip {
    border-radius: 6px;
    padding: 4px 4px 2px 4px;
    width: fit-content;
}

.subscription-chip {
    width: fit-content;
    border-radius: 2px;
    padding: 2px 3px;
}

.inr-number-text {
    text-decoration: line-through;
    text-decoration-color: #aaaaaa;
}

.view-packages-button4 {
    background-color: white !important;
}

.need-assistance-body {
    border-radius: 12px;
    background-color: #d6f6e3;
}

.contact-chip-body {
    border-radius: 16px;
    padding: 8px 16px 6px;
}

.inr-text-color {
    color: #ffffff99;
    text-decoration-color: #ffffff99;
}

.billing-info-text-padding {
    padding: 14px 16px 2px 16px;
    border-radius: 8px 8px 0px 0px;
}

.billing-detail-card-padding {
    padding: 16px 20px 20px 20px;
    border-radius: 0px 0px 8px 8px;
}

.account-detail-width {
    width: 150px;
}

.billing-address-width {
    width: 110px;
}

.billing-info-line-divider {
    height: 1px;
    background-color: #0000000f;
}

.content-card-scrollbar {
    overflow: auto;
    max-height: 140px;
    scrollbar-width: thin;
    width: 250px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.content-card-scrollbar-free-plan {
    overflow: auto;
    max-height: 270px;
    scrollbar-width: thin;
    width: 250px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.content-card-scrollbar:hover {
    &::-webkit-scrollbar {
        width: 3px;
        display: block;
    }

    &::-webkit-scrollbar-track {
        background-color: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #888;
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }
}

.figure-media-margin {
    ul {
        padding-inline-start: 18px !important;
    }

    ul li {
        list-style: outside !important;
    }

    ol {
        padding-left: 16px !important;
    }

    ol li {
        list-style: auto !important;
    }
}

.subscription-card-list-ul ul {
    max-width: 250px;
}

.close-icon-bg {
    color: #8e8e8e !important;
    width: 28px;
    min-width: 28px !important;
    padding: 0px !important;
    justify-content: flex-start !important;

    &:hover {
        background-color: transparent !important;
    }
}

.validity-month-text {
    span {
        font-weight: bolder;
        width: 63px;
        min-width: 40px;
        text-align: center;
        word-break: break-all;
    }
}

@media (min-height: 814px) {
    .subscription-modal-footer {
        position: absolute;
        bottom: 0 !important;
    }

    .need-assistance-position {
        bottom: 71px;
        position: absolute;
    }
}

@media (min-width: 1279px) {
    .need-assistance-body {
        height: 44px;
    }
}