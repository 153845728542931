// need to move this style to component side
@import "~doct-core/build/variables.scss";

.doct-text-editor {
    overflow: hidden;

    &.quill {
        .ql-container {
            &.ql-snow {
                background-color: rgba($white, 0.6);
                transition: background 300ms ease;
            }
        }

        &.quill-focused {
            .ql-container {
                &.ql-snow {
                    background-color: $white;
                }
            }
        }

        .ql-toolbar {
            padding-left: 0;
            margin: 0 -5px;
        }

        .ql-formats {
            margin-right: 0;

            .ql-stroke {
                stroke: $grey-600;
            }

            .ql-fill {
                fill: $grey-600;
            }
        }
    }

    &.text-editor-on-error {
        .ql-container {
            border-bottom-width: 1px;
            border-bottom-color: $semantic-danger;
        }
    }
}

.ql-container.ql-snow {
    border: 0 none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom: 2px solid $grey-300;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    background: $white;
}

.ql-snow.ql-toolbar {
    display: block;
    background: transparent;
    border: 0 none;
}

.ql-editor {
    min-height: 160px !important;
}

.ql-editor.ql-blank::before {
    font-style: normal;
    color: $grey-600;
    font-size: 14px;
}