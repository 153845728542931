$informative : #4389FF;

.mt-12px {
    margin-top: 12px !important;
}

.hr-opacity {
    height: 1px;
    opacity: 0.1;
    border-bottom: 1px solid;
}

.hr-dotted {
    border-bottom: dotted !important;
}

.genrate-e-certificate {
    max-width: 474px;
    max-height: 474px;
}

.fs-12 {
    font-size: 12px !important;
}

.border-circle {
    border: 2px solid rgba($informative, 0.25);
    width: 36px;
    height: 36px;
    border-radius: 50%;
}

.border-circle-grey {
    border: 2px solid #0000001a !important;
}

.file-name-input {
    div input {
        padding: 14px 12px !important;
    }

    ::before,
    ::after {
        border-bottom: none !important;
    }
}

.upload-certificate-main-card {
    width: 866px;
    height: 389px;
    border: 1px dashed #AAAAAA;
    border-radius: 8px;
    background-color: #ECECEC;
    padding: 34px;
}

.must-note-card {
    width: 798px;
    height: 152px;
    background: #D1DCEF;
    border-radius: 8px;
}

.must-note-points {
    line-height: 22px !important;
}

.view-sample-card {
    background-color: #EDF1F9;
    border-radius: 8px;
    width: 244px;
    height: 131px;
}

.loader-outer-line {
    border-radius: 22px;
    height: 36px;
}

.select-file-button {
    background-color: #4389FF !important;
}

.will-do-later-button {
    background-color: #F2F2F2 !important;
}

.assign-via-grey-button {
    background-color: #ECECEC !important;
}

.got-it-button-bg {
    background-color: #E0E7F2 !important;
}

.modal-hidden {
    &.doct-modal .doct-modal-content-wrapper .doct-modal-header {
        border-radius: 12px 12px 0px 0px !important;
    }

    &.doct-modal .doct-modal-footer {
        border-radius: 0px 0px 12px 12px !important;
    }
}

.disable_modal_outside_click {
    .MuiBackdrop-root {
        pointer-events: none;
    }
}

.hide-modal-header {
    &.doct-modal {
        .doct-modal-header {
            display: none !important;
        }
    }
}

.info-body-modal {
    .doct-modal-body {
        background-color: #ECF3FF !important;
    }
}

.demo-certificate-header {
    background-color: #00000029 !important;
}

.demo-certificate-text {
    margin-top: 17px;
    margin-bottom: 17px;
}

.modal-scrollbar {
    ::-webkit-scrollbar {
        width: 0 !important;
        background: transparent !important;
    }
}

.certificate-card {
    height: 155px;
    box-shadow: 0px 1px 2px #00000008;
    border-radius: 8px;
}

.no-awardees {
    color: #0003;
}

.assign-awardee-button {
    color: #4389FF !important;
    border-color: #4389ff40 !important;
    height: 24px !important;
}

.assign-via-chip {
    width: 79px;
    border-radius: 8px 8px 0px 0px;
}

.assign-via-card {
    width: 278px;
    height: fit-content;
    background: #F9F9F9;
    border: 1px solid #0000001A;
    border-radius: 0px 12px 12px 12px;
    padding: 20px;
}

.assign-card-button {
    &.doct-button {
        padding: 8px 13px !important;
    }
}

.remove-button-bg {
    background-color: #FFB200 !important;
}

.ready-assign-card {
    box-shadow: 0px 2px 2px #0000000F;
    border: 2px solid #4389FF;
    border-radius: 6px;
    padding: 20px 24px 21px 24px;
}

.clicked-remove-button {
    background-color: #F1F1F1 !important;
    border: 1px dashed #AAAAAA;
    border-radius: 6px;
    padding: 18px 24px 22px 24px;
}

.assign-dashboard-card {
    background: #FFFFFF;
    box-shadow: 0px 1px 2px #00000008;
    border-radius: 8px;
    padding: 14px 12px 14px 16px;
}

.table-cell-text {
    background: #F9F9F9 !important;
    border-radius: 6px !important;

    th {
        &.MuiTableCell-root {
            color: #aaaaaac6 !important;
            line-height: 16px !important;
            padding: 9px 16px !important;
            font-size: 12px !important;
        }
    }
}

.table-cell-list {
    &.MuiTableCell-root {
        padding: 3px 16px !important;
    }
}

.table-cell-name {
    &.MuiTableCell-root {
        font-size: 14px !important;
        color: #242424 !important;
        line-height: 19px !important;
        width: 167px !important;
        word-break: break-all;
    }
}

.table-cell-date {
    &.MuiTableCell-root {
        font-size: 13px !important;
        color: #717171 !important;
        line-height: 16px !important;
    }
}

.awardee-actionMenu-bg {
    & .doct-button.doct-contained-button.doct-secondary-button {
        background-color: transparent !important;
    }
}

.awardee-tableContainer {
    box-shadow: none !important;
    border-radius: 6px !important;
    max-height: 46vh !important;
    overflow-y: scroll;
    scrollbar-width: none !important;

    &::-webkit-scrollbar {
        display: none !important;
    }
}

.draggable-input-text {
    // width: 323px;
    //     height: 49px;
    //     text-align: center !important;
    padding: 0px !important;
    min-width: 225px;
    min-height: 30px;
    width: auto;
    height: auto;
    max-width: 793px !important;
    max-height: 300px !important;
    border: 2px dotted #000000 !important;
    background:
        linear-gradient(to right, black 4px, transparent 4px) 0 0,
        linear-gradient(to right, black 4px, transparent 4px) 0 100%,
        linear-gradient(to left, black 4px, transparent 4px) 100% 0,
        linear-gradient(to left, black 4px, transparent 4px) 100% 100%,
        linear-gradient(to bottom, black 4px, transparent 4px) 0 0,
        linear-gradient(to bottom, black 4px, transparent 4px) 100% 0,
        linear-gradient(to top, black 4px, transparent 4px) 0 100%,
        linear-gradient(to top, black 4px, transparent 4px) 100% 100%;
    background-repeat: no-repeat;
    background-size: 14px 14px;
    background-color: #F9F9F9 !important;
}

.draggable-input-text::placeholder {
    font-size: 22px !important;
    color: #00000033 !important;
    opacity: 1 !important;
    font-weight: bold !important;
}

.draggable-input-tooltip {
    background-color: #002830 !important;
    color: '#FFFFFF';
    border-radius: 8px;
    padding: 10px;
    margin: 0px;
}

.assign-via-dashboard-modal {
    .doct-modal-header {
        border-bottom: none !important;
        padding: 22px 24px 0px 24px !important;

        svg {
            color: #000000 !important;
        }
    }

    .doct-modal-body {
        padding: 12px 24px 24px 24px !important;
    }

    .doct-modal-footer {
        .doct-primary-button {
            background-color: $informative !important;
        }
    }
}

.select-attendee-type {
    &.MuiFormControl-root {
        width: 256px !important;
    }
}

.edit-button-text {
    color: $informative !important;
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.save-certificate-button-bg {
    background-color: #002830 !important;
}

.on-click-save-certificate-button-bg {
    background-color: #91a9a69c !important;
}


.file-uploader-bg {
    .uploaded-file-container {
        margin-top: 8px !important;
    }

    .file-input-section .file-input-wrap {
        margin-top: 0px !important;
        margin-left: 12px !important;
        justify-content: center;
    }
}

.disabled-link {
    color: #d3d3d3 !important;
    background-color: #00000000 !important;
}

.send-now-bottom-list-body {
    width: 291px;
    height: 54px;
    border-radius: 27px;
}

.send-now-bottom-digit {
    border-radius: 27px;
    width: 46px;
    height: 46px;
    background-color: #335359;
}

.send-now-text-body {
    width: 123px;
    height: 46px;
    border-radius: 27px;
}

.awardee-listing-page-height {
    height: 58vh;
}

.share-button-hide {
    &.doct-button {
        display: none !important;
    }
}

.max-text-width {
    max-width: 455px;
}

.all-e-certificate-scrollbar {
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 75vh;
    scrollbar-width: none;
}

@media (min-height: 768px) {
    .awardee-listing-page-height {
        height: 70vh;
    }

    .awardee-tableContainer {
        max-height: 65vh !important;
    }
}

@media(min-width: 1280px) {
    .certificate-card-width {
        width: 866px;
    }
}

@media(max-width: 1279px) {
    .certificate-card-width {
        width: auto;
    }

    .assign-via-cards-max-width {
        max-width: 866px;
        overflow-x: scroll;
    }
}