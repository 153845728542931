.mySubscription-card-body {
    height: 100px;
    box-shadow: 0px 1px 2px #00000008;
    border-radius: 8px;
    padding: 20px;
}

.instituteIcon-bg {
    border-radius: 50%;
    background-color: #E5F8F1;
}

.text-mb {
    margin-bottom: 2px !important;
}