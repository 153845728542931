.card-activity-log-thumbnail {
    max-width: 150px;
    max-height: 100px;
    width: 100%;
    height: auto;    

    img {
        width: 100%;
        height: 100%;
    }
}

.card-activity-log-action-menu {
    top: 0px;
    right: 8px;
}