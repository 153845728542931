.usage-card-body {
    border-radius: 6px;
    border: 1px solid #00000014;
    width: 253px;
    height: fit-content;
}

.usage-card-line-divider {
    border-top: 1px solid #00000014;
}

.usage-card-text {
    letter-spacing: 0.22px;
}

.upgrade-card-br {
    border-radius: 4px;
}

.free-plan-card-bg {
    background-color: #e6ecf5;
}

.total-usage-btn-bg {
    background-color: transparent !important;
    min-width: 18px !important;

    &:hover {
        background-color: transparent !important;
    }
}

.total-usage-normal-btn {
    color: #717171 !important;
}

.total-usage-active-btn {
    color: #00A0C0 !important;
}