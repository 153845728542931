.enterprise_form_header_container {
    position: sticky;
    top: 0;
    z-index: 999;
    height: 54px;
}

.confirmation-screen-height {
    height: 90vh;
    overflow: scroll;
}
.footer-bottom-none {
    min-height: 64px;
    position: -webkit-sticky;
    position: fixed !important;
    bottom: 0;
    z-index: 4;
}
.invoice-button-bg {
    background-color: #4389ff !important;
}
