.profile_photo_square {
    width: 140px;
    height: 140px;   
    overflow: hidden;
    margin-left: 93px !important;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.upload_btn{
    width: 136px !important;
    margin-left: 93px !important;
}