@import "~doct-core/build/variables.scss";

.status_container_success {
    background: rgba(0, 199, 82, 0.06)
}

.status_container_failure {
    background: rgba(234, 67, 53, 0.06)
}

.offer_card {
    width: 376px;
}


.card_bg_low_opacity {
    background-color: rgba(255, 255, 255, 0.3);
}

.semantic-success-btn-with-low-opacity {
    background-color: rgba($semantic-success,0.1);
    color: rgba($black, 0.6);
}