.billing-info-card-br {
    border-radius: 8px;
}

.amount-text-padding {
    padding-bottom: 10px;
}

.amount-text-line-divider {
    height: 1px;
}

.total-amount-text-padding {
    padding-top: 6px;
    padding-bottom: 6px;
}

.failed-msg-padding {
    padding: 11px 0px 9px 12px;
    border-radius: 6px;
    background-color: #FDECEA;
}