.job-usage-card-border {
    border: 1px solid #00000014;
    border-radius: 6px;
}

.job-usage-card-divider-line {
    border: 1px solid #00000014;
}

.job-usage-card-bg {
    background-color: #F5EFE1;
}

.plan-limit-text {
    color: #C18700;
}

.view-plan-bg {
    background-color: #F5FAF7;
}

.job-usage-green-card {
    background-color: #E6F2EB;
}

.planTextWeight {
    color: #000000 !important;
    font-weight: bold !important;
}