.mb-2px {
    margin-bottom: 2px;
}

.pb-6px {
    padding-bottom: 6px;
}

.px-12px {
    padding-left: 12px;
    padding-right: 12px;
}

.py-20px {
    padding-top: 20px;
    padding-bottom: 20px;
}

.px-20px {
    padding-left: 20px;
    padding-right: 20px;
}

.pt-12px {
    padding-top: 12px;
}

.mt-6px {
    margin-top: 6px;
}

.ml-6px {
    margin-left: 6px;
}

.mr-6px {
    margin-right: 6px;
}


.pb-12px {
    padding-bottom: 12px;
}

.py-12px {
    @extend .pt-12px;
    @extend .pb-12px;
}

.mt-12px {
    margin-top: 12px;
}

.mb-12px {
    margin-bottom: 12px;
}

.ml-12px {
    margin-left: 12px;
}

.pt-6px {
    padding-top: 6px
}

.pb-6px {
    padding-bottom: 6px
}

.mr-12px {
    margin-right: 12px;
}

.form-heading-mb {
    @extend .mb-12px;
}