.doct-top-bar-branding {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

.doct-navigation-area {
    top: 52px;
}

.static-list-item {
    li {
        list-style: disc;
    }
}

.box-shadow {
    box-shadow: 0px 1px 2px #00000008;
}

.border-radius-tl {
    border-top-left-radius: $border-radius;
}

.border-radius-tr {
    border-top-right-radius: $border-radius;
}

.border-radius-bl {
    border-bottom-left-radius: $border-radius;
}

.border-radius {
    border-bottom-right-radius: $border-radius;
}

.border-radius {
    @extend .border-radius-bl;
    @extend .border-radius;
    @extend .border-radius-tl;
    @extend .border-radius-tr;
}

.border-1px {
    border-width: 1px;
    border-style: solid;
}

.border-white {
    border-color: $white;
}

.border-top-1px {
    border-top-width: 1px;
    border-top-style: solid;
}

.border-bottom-1px {
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.last-child-no-border {
    .border-bottom-1px {
        &:last-child {
            border-bottom-width: 0;
        }
    }
}

.border-bottom-primary-100 {
    border-bottom-color: $primary-100;
}

.line-divider {
    height: 1px;
}

.line-divider-2px {
    height: 2px;
    min-height: 2px;
}

.text-initial {
    text-transform: initial !important;
}

.paper-with-radius-shadow {
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: 0px 1px 2px #00000008;
}

.higher-z-index {
    z-index: $higher-z-index;
}

.full-page-loader {
    position: fixed;
    @extend .higher-z-index;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.app-loader {
    @extend .full-page-loader;
    background-color: $white;
}

.content-loading {
    .page-loading {
        height: 100%;
        width: 100%;
        position: absolute;
        min-height: 30px;
    }
}

.free-post-chip-border {
    border: 1px solid #717171;
    border-radius: 2px;
}

.gst-number-capitalized {
    input {
        text-transform: uppercase !important;
    }
}

.absolute-center {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.white-color-svg {
    svg {
        color: $white;
    }
}

.loader-min-height {
    min-height: 46px;
}

.table-container {
    overflow-y: auto;
    height: 515px;
    scrollbar-width: unset !important;

    &::-webkit-scrollbar {
        display: none !important;
    }
}

.top-0 {
    top: 0;
}

.divider-line {
    border-bottom: 1px solid #80808030;
}

.card_dropdown_menu {
    width: 246px;
    right: 0;
    top: 18px;
    box-shadow: $box-shadow;
    border: 1px solid $grey-300;
    background: $white;
    border-radius: $border-radius;
    overflow: hidden;

    .card_dropdown_menu-item {
        padding: 12px 16px;

        &:hover {
            background-color: $grey-100;
        }
    }
}

.slider-flex-slick-track {
    .slick-track {
        display: flex;
    }
}

.logo-slider {
    .slick-slide {
        height: auto;

        div {
            height: 100%;
        }
    }
}

.img-fluid {
    max-width: 100% !important;
}

.page-loading-width-auto {
    .page-loading {
        width: auto !important;
    }
}

.pointer-event-none {
    pointer-events: none;
}

.pointer-event-initial {
    pointer-events: initial;
}

.disabled_modal_outside_click {
    .MuiBackdrop-root {
        pointer-events: none;
    }
}

.modal_hide_secondary_button {
    .doct-modal-footer {
        .doct-secondary-button {
            display: none !important;
        }
    }
}

.modal-loading {
    .doct-modal-footer {
        @extend .pointer-event-none;
    }
}

.modal-content-position-relative {
    .doct-modal-content-wrapper {
        position: relative;
    }
}

.loader-transparent-bg {
    background-color: rgba($white, 0.5);
}

.inside-modal-loader {
    z-index: 1;
    top: 47px;
}

.disable-sidebar-link-item {
    pointer-events: none;
    opacity: 0.3;
}

.disable-with-low-opacity {
    pointer-events: none;
    opacity: 0.5;
}

.content-with-animated-loader-area {
    position: relative;

    &.content-with-animated-loader-area-min-height {
        min-height: 124px;
    }

    .content-animated-loader-row {
        z-index: 2;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        .page-loading {
            height: 100%;
            width: auto !important;
        }

        &.transparent-bg {
            .page-loading {
                background-color: transparent;
            }
        }

        &.content-animated-loader-row-min-height {
            min-height: 124px;
        }
    }
}

.hide-modal-header {
    &.doct-modal {
        .doct-modal-header {
            display: none !important;
        }
    }
}

.doct-modal {
    &.privacy-policy-modal {
        z-index: $z-index-above-all-elements; // added as fixes for modal is behind other elements on event registration module
    }
}

.react-datepicker__aria-live {
    position: absolute;
    clip-path: circle(0);
    border: 0;
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    width: 1px;
    white-space: nowrap;
}

.bg-color-pending { 
    background-color: #ffb200;
}


.custom-validation-error {
    font-size: 11px;
    line-height: 14px;
    padding-left: 12px;
    color: #ea4335;
}

.doct-outline-pills {
    border: 1px solid rgba(46, 45, 45, 0.26);
    border-radius: 16px;
    padding: 6px 11px;

    &+.doct-outline-pills {
        margin-left: 8px;
    }
}

.line-height-0 {
    line-height: 0;
}

.transparent-action-menu {
    .doct-round-button {
        background-color: transparent !important;
    }
}

.card-vertical-spacer {
    &+.card-vertical-spacer {
        margin-top: 12px;
    }
}

.billing-detail-more-action-menu {
    .doct-button.doct-contained-button.doct-inverse-button {
        background-color: transparent !important;
        transform: rotate(90deg);
    }
}

.lh-35 {
    line-height: 35px !important;
}

.text-underline {
    text-decoration: underline !important;
}

.text-word-break {
    word-break: break-all !important;
}

.lh-2 {
    line-height: 2 !important;
}

.font-family-roboto {
    font-family: Roboto;
}

.text-truncate-chip-width,
.min-education-chip-truncate {
    & div span {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        width: fit-content !important;
    }
}

.text-truncate-chip-width div span {
    max-width: 522px !important;
}

.min-education-chip-truncate div span {
    max-width: 1095px !important;
}

.social-share-menu {
    box-shadow: 0px 3px 3px #0000001a;
    border: 1px solid #0000000f;
    border-radius: 6px;
}

.ck-editor-p-margin {
    p {
        margin-top: 0px;
        margin-bottom: 0px;
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

@media (max-width: 767px) {
    .text-truncate-chip-width div span {
        max-width: 269px !important;
    }

    .min-education-chip-truncate div span {
        max-width: 265px !important;
    }
}

@media (min-width: 768px) and (max-width: 1023px) {
    .text-truncate-chip-width div span {
        max-width: 306px !important;
    }

    .min-education-chip-truncate div span {
        max-width: 675px !important;
    }
}

@media (min-width: 1024px) and (max-width: 1279px) {
    .text-truncate-chip-width div span {
        max-width: 426px !important;
    }

    .min-education-chip-truncate div span {
        max-width: 915px !important;
    }
}

.certificate-img img {
    width: auto !important;
    // max-width: 866px !important;
    // max-height: 616px !important;
    height: auto !important;
}