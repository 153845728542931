@import "~doct-core/build/variables.scss";
@import url("https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@600&display=swap");

$input-height: 46px;
$inquiry-border-radius: 12px;

.custom-button-branding-page {
    white-space: nowrap;
    border-radius: $inquiry-border-radius;
    padding-left: 24px;
    padding-right: 24px;
    font-family: "Manrope", sans-serif;
}

.inquiry-form {
    .inquiry-form-input {
        font-size: 16px !important;
        height: $input-height;
        border-radius: $inquiry-border-radius;
        border: 1px solid rgba($black, 0.08);
    }

    .custom-button {
        :global(.doct-button) {
            background: rgba($semantic-info, 0.16);
            color: $semantic-info;
            @extend .custom-button-branding-page;
        }
    }

    .disabled-custom-button {
        :global(.doct-button) {
            background: rgba(#000000, 0.03);
            font-weight: 600;
            @extend .custom-button-branding-page;
        }
    }

    .disable-text{
        color: #9c9b9b !important;
    }

    .helper-text-color { 
        color: rgba($black, 0.4);
    }

    .input-error {
        border: 1px solid red;
    }
}

.title-need-assistance {
    font-family: "Zilla Slab", serif !important;
}

@media (max-width: 767px) {
    .text-sm {
        font-size: 14px;
    }
}

@media (min-width: 1024px) {
    .helper-text-gap {
        margin-bottom: 20px;
    }
}
