.current-committee-screen {
    height: calc(100vh - 226px);
}
.committee-member-box {
    border: 1px solid #0000000f;
    border-radius: 6px;
    padding: 6px 11px;
}
.add-committee-icon {
    right: 0;
}
.more_action_menu {
    .doct-button.doct-contained-button.doct-inverse-button {
        transform: rotate(90deg);
    }
    .doct-button.doct-contained-button.doct-secondary-button {
        background-color: rgba(0, 0, 0, 0.01) !important;
    }
}

.btn-archives {
    right: 0;
    margin-top: -59px;
}

.disable_color_text.doct-action-menu-item.MuiMenuItem-root {
    color: rgb(229, 229, 229) !important;
    pointer-events: none;
}

.archives-background-tab-color {
    background-color: rgba(0, 0, 0, 0.03);
}
