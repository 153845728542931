.membership_card {
    background-color: white;
    max-width: 328px;
}

.membership_card_line {
    height: 1.5px;
}

.right-0 {
    right: 0px;
}

.bg-active-green {
    background-color: #00c752 !important;
}

.membership_section_container {
    margin-bottom: 130px;
}

.membership_modal .doct-modal-body::-webkit-scrollbar {
    width: 0px !important;
}

.membership_card_options {
    transform: rotate(90deg);
}

.membership_modal .doct-modal-header {
    background-color: white !important;
}