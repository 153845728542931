.section_heading_container {
    min-height: 64px;
    border-radius: 12px 12px 0px 0px;
}

.form_card_section_heading {
    opacity: 0.6;
}

.form_card_child_element {
    margin-top: 44px;
    padding-bottom: 54px;
}
