@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@500;600;700;800;900&display=swap");

.page-container {
    min-height: 100vh;
}

.centered-container {
    flex-grow: 1;
}

.before-login-page-border {
    border: 3px solid #0000000f;
    border-radius: 24px;
}

.doctIcon-padding {
    padding-top: 87px;
    padding-bottom: 22px;
}

.before-loginPage-title {
    font-family: "Manrope", sans-serif;
    font-size: 38px !important;
}

.login-button-padding {
    padding-top: 34px;
    padding-bottom: 70px;
}

.learn-more-button-border {
    color: #01505F !important;
    border-color: #01505F !important;
    font-weight: 800 !important;
}

.login-button-inner-padding {
    padding-left: 34px !important;
    padding-right: 34px !important;

    &:hover {
        background-color: #002830 !important;
    }
}

.login-button-inner-padding,
.learn-more-button-border {
    font-family: "Manrope", sans-serif !important;
}

@media (min-width: 1024px) {
    .footer-padding {
        padding-top: 29px;
        padding-bottom: 29px;
        bottom: 0px;
    }
}

@media (max-width: 1023px) {
    .login-text-card {
        background-color: #4389ff33;
        border-radius: 16px;
    }

    .footer-divider-responsive {
        border: 2px solid #0000000f;
    }
}

@media (max-width: 767px) {

    .before-loginPage-title {
        font-size: 28px !important;
    }

    .doctIcon-padding,
    .login-button-padding {
        padding-top: 31px;
        padding-bottom: 20px;
    }

    .doctIcon-size-responsive {
        width: 32px;
        height: 32px;
    }
}