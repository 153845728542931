.profile-data-box {

    &:last-child {
        margin-bottom: 24px;
    }

    & + .profile-data-box {
        margin-top: 12px;
    }

    .profile-data-box-content-title {
        min-width: 110px;
    }
}
