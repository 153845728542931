.preview_institute_image {
    max-width: 84px;
    max-width: 84px;
    object-fit: cover;
}

.course_verified_button {
    font-size: 12px;
    border-radius: 10px;
}

.course_preview_key_features p {
    max-width: 185px;
}

.doct-outline-pills {
    border: 1px solid rgba(46, 45, 45, 0.26);
    border-radius: 16px;
    padding: 6px 11px;

    & + .doct-outline-pills {
        margin-left: 8px;
    }
}