$max-width: 298px;

.profile-dropdown {
    right: 0;
    top: 48px;

    &.profile-dropdown-box {
        box-shadow: 0px 4px 2px #00000014;
        border: 1px solid #00000014;
        max-width: $max-width;
        width: 100%;
    }

    .profile-dropdown-avatar {
        width: 100%;
        max-width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
        }
    }
}