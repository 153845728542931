.preview_jobs_image {
    width: 84px;
    height: 84px;
    object-fit: cover;
}

.job_verified_button {
    font-size: 12px;
    border-radius: 10px;
}
.full-page-hight {
    min-height: calc(100vh - (48px + 78px));
    overflow: auto;
}
