.product_objective_text {
    background-color: rgba(67, 137, 255, 0.2);
}
.border-radius-12 {
    border-radius: 12px;
}
.bussines_counselling_container {
    margin-left: 110px;
}
.background_container {
    background-color: #00c75212;
}
