$common-subscription-br: 8px;

.package-details-card-body {
    box-shadow: 0px 1px 2px #00000008;
    border-radius: $common-subscription-br;
}

.total-price-card-body {
    border-radius: $common-subscription-br;
    background-color: #ffffff99;
}

.text-line-divider {
    background-color: #0000000f;
    height: 1px;
}

.sticky-footer-bottom {
    bottom: 0px;
}
.figure-media-margin {
    ul li {
        list-style: inside !important;
    }

    ol {
        padding-left: 16px !important;
    }

    ol li {
        list-style: auto !important;
    }
}

.for-bulles-adding {
    list-style: disc;
}
