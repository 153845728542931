.rejection_reason_modal .doct-secondary-button {
    display: none !important;
}

.rejection_reason_modal .doct-primary-button {
    background-color: #EA4335 !important;
}

.rejection_reason_modal .doct-primary-button:hover {
    background-color: #ee2b19 !important;
}