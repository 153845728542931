/*
* here the style which is clashing in app side
* added override style
* this changes need to impliment through lib side
*/

// Date picker
.MuiPickersDay-root {
    border-radius: 50% !important;
}


// Custom error
.custom-error {

    .MuiFormHelperText-root.Mui-error,
    .MuiFormLabel-root {
        color: #EA4335;
    }

    .MuiFormHelperText-root.Mui-error {
        font-size: 11px;
        line-height: 14px;
        padding-left: 12px;
    }

    .MuiFilledInput-root {
        &:before {
            border-bottom-color: #d32f2f;
        }
    }

    &.MuiFormHelperText-root {
        padding-left: 12px;
    }
}

.white-body-modal {
    .doct-modal-body {
        background-color: $white !important;
    }
}

.doct-modal-extra-padding {
    .doct-modal-body {
        padding: 24px !important;
    }
}

.action-menu {
    .doct-contained-button.doct-secondary-button {
        background-color: transparent !important;

        &:hover {
            background-color: rgba(0, 0, 0, 0.05) !important;
        }
    }
}

.doct-checkbox {
    &.Mui-checked {
        color: #00A0C0 !important;
    }

    &.MuiCheckbox-root {
        padding: 9px !important;
    }
}

.doct-input {
    &.disabled {
        .MuiAutocomplete-endAdornment {
            display: none;
        }
    }
}

.MuiPaginationItem-root {
    &.MuiPaginationItem-circular {
        border-radius: 16px;
    }
}

.doct-dropdown-select {
    .MuiInputBase-root {
        font-size: 14px;
        max-width: 435px;
    }
}

.doct-action-menu-item.MuiMenuItem-root {
    &.text-danger {
        color: #EA4335 !important;
    }
}

.doct-modal-content-wrapper {
    opacity: 1 !important; // for avoid opacity 0 (when using switch inside modal, opacity being zero)
}

@media (max-width: 767px) {
    .for-organization-title {
        margin-left: 0px !important;
    }

    .footer .container .row {
        margin-top: -24px;
    }
}

@media(max-width: 1279px) {
    .doct-dropdown-select {
        .MuiInputBase-root {
            max-width: 315px;
        }
    }
}