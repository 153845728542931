$common-subscription-br: 8px;

.package-details-card-body {
    box-shadow: 0px 1px 2px #00000008;
    border-radius: $common-subscription-br;
}

.subscription-chip {
    width: fit-content;
    border-radius: 2px;
    padding: 2px 3px;
}
