.unpublish_course_modal .doct-secondary-button {
    display: none !important;
}

.unpublish_course_modal .doct-primary-button {
    background-color: #EA4335 !important;
}

.unpublish_course_modal .doct-primary-button:hover {
    background-color: #ee2b19 !important;
}

.unpublish_course_modal .MuiTypography-body1 {
    line-height: normal !important;
}