.accordion {
    border-radius: 8px;
    background-color: #F7F7F7 !important;

    .accordion-title {
        padding: 10px 16px;

        .expand-icon {
            transform: rotate(0deg);
            transition: transform 0.3s ease;

            &.expanded {
                transform: rotate(180deg);
            }
        }
    }

    .accordion-subtitle-padding {
        padding: 12px 16px;
    }
}