.date_picker {
  width: 246px !important;
}
.MuiTypography-body1 {
  // line-height: 0 !important;
  font-size: 14px !important;
}
.basic_info_state {
  width: 266px !important;
}
